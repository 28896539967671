// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA3Q1CkVpqDH2WiCq9Ri4IPWkWBTjLI9eA',
    authDomain: 'img-preguntaz.firebaseapp.com',
    databaseURL: 'https://img-preguntaz.firebaseio.com',
    projectId: 'img-preguntaz',
    storageBucket: 'img-preguntaz.appspot.com',
    messagingSenderId: '739882594321',
    appId: '1:739882594321:web:5d658d8b380cfe5c'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
