import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Agrega estos módulos
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
// Formularios
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// Servicio
import { FirebaseStorageService } from './services/firebase-storage.service';

import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { AngularFireAuthModule } from '@angular/fire/auth';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    FirebaseStorageService,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
