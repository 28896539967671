import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {

  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoggedIn = false;

  constructor(
    public afAuth: AngularFireAuth
  ) { }

  public setLogin(token: string) {
    localStorage.setItem('token', token);

    this.isLoggedIn = true;
    this.isLoggedIn$.next(true);
  }

  login() {
    return new Promise((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(res => {
        this.afAuth.auth.currentUser.getIdToken(true).then(idToken => {
          this.setLogin(idToken);
          resolve(idToken);
        });
      });
    });
  }

  public setLogout(): void {
    localStorage.removeItem('token');

    this.afAuth.auth.signOut();
    this.isLoggedIn = false;
    this.isLoggedIn$.next(false);
  }

  public recoverSession() {
    const token = localStorage.getItem('token');

    if (token) {
      this.isLoggedIn = true;
      this.isLoggedIn$.next(true);
    }
  }
}
